<template>
  <div class="lists-menu">
    <v-list>
      <div class="text-h6 primary--text mb-3 font-weight-bold ml-2">
        LE MIE LISTE
      </div>
      <!-- v-bind:to="'/profile/lists/' + list.id" -->
      <div class="my-list-container" ref="listsContainer">
        <v-list-item :to="{ name: 'List', params: { listId: 'preferred' } }">
          <i18n
            :path="hasFavorites ? 'list.preferred' : 'list.bestselling'"
            tag="strong"
          />
        </v-list-item>
        <v-list-item
          v-for="(list, index) in listsData"
          :key="list.id"
          :to="{ name: 'List', params: { listId: list.id } }"
          :class="{ zebra: index % 2 == 0 }"
          v-ripple="false"
        >
          <v-row
            no-gutters
            align="center"
            :class="{ default: list.isDefault }"
            v-if="!list.isEdit"
          >
            <v-col cols="6" md="7">
              <strong class="name">{{ list.name }}</strong>
            </v-col>
            <v-col
              cols="6"
              md="5"
              class="d-flex justify-end justify-content-end align-center"
            >
              <v-tooltip left v-if="!list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-on:click.prevent="setDefault(list.id)"
                    class="third-icon-button mr-1 primary--text"
                  >
                    <v-icon>mdi-star-outline</v-icon>
                  </v-btn>
                </template>
                <span> Imposta come lista preferita</span>
              </v-tooltip>
              <v-tooltip left v-if="list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-on:click.prevent=""
                    class="third-icon-button mr-1 primary--text"
                  >
                    <v-icon>mdi-star</v-icon>
                  </v-btn>
                </template>
                <span> Questa lista è la tua preferita</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    large
                    icon
                    v-on:click.prevent="setEdit(list)"
                    class="third-icon-button mr-1 primary--text"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>
                </template>
                <span>Rinomina lista</span>
              </v-tooltip>

              <v-tooltip left v-if="!list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    large
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-on:click.stop.prevent="removeList(list.id, list.name)"
                    class="third-icon-button primary--text"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </template>
                <span>Elimina lista</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row no-gutters align="center" v-if="list.isEdit">
            <!-- label="Inserisci il nome" -->
            <v-col cols="8" sm="8">
              <v-text-field
                flat
                dense
                hide-details="auto"
                v-model="list.tempName"
                v-on:click.prevent="() => {}"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="2" sm="2"> </v-col> -->
            <v-col cols="4" sm="4" class="d-flex flex-row justify-end">
              <v-btn
                class="third-icon-button primary--text"
                large
                icon
                v-on:click.prevent="confirmEdit(list)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn
                class="third-icon-button primary--text"
                large
                icon
                v-on:click.prevent="undoEdit(list)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </div>
    </v-list>

    <v-divider class="my-5"></v-divider>

    <v-row class="d-flex flex-column create-list-wrapper" no-gutters>
      <div class="text-h6 primary--text mb-3 font-weight-bold ml-2">
        CREA UNA NUOVA LISTA
      </div>

      <div class="create-input d-flex align-center">
        <v-text-field
          label="Inserisci il nome"
          hide-details="auto"
          v-model="listName"
        ></v-text-field>
        <v-btn
          medium
          depressed
          v-ripple="false"
          class="secondary"
          v-on:click="create()"
          :disabled="createDisabled"
          >CREA</v-btn
        >
      </div>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import list from "~/mixins/list";

export default {
  name: "ListsMenu",
  mixins: [list],
  // props: ["lists"],
  props: {
    listsData: { type: Array, required: true }
  },
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    async create() {
      await this.createList();
      this.$emit("created");
    },
    setDefault(listId) {
      this.$emit("setdefault", listId);
    },
    removeList(listId, listName) {
      this.$emit("remove", { listId, listName });
    },
    setEdit(list) {
      Vue.set(list, "isEdit", true);
      Vue.set(list, "tempName", list.name);
      //   list.isEdit = true;
      //   list.tempName = list.name;
      //   this.$emit("remove", listId);
    },
    undoEdit(list) {
      list.isEdit = false;
      list.tempName = null;
    },
    confirmEdit(list) {
      this.$emit("rename", list);
    },
    async addAllToCart(list) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(list.id);
      }
    },
    setOpenList(listId) {
      console.log(listId);
      this.setOpenedListId(listId);
    }
  },
  created() {
    console.log(this);
  },
  watch: {
    listsData(val, oldVal) {
      if (val !== oldVal) {
        this.$nextTick(() => {
          this.$refs.listsContainer.scrollTop =
            this.$refs.listsContainer.scrollHeight + 60;
        });
      }
    }
  }
};
</script>

<template>
  <div class="generic-list scroll-container">
    <!-- <v-container class="fill-height"> -->

    <v-row no-gutters class="list-page">
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <!-- menu laterale sx: elenco liste -->
        <MenuNavigator class="menu-navigator" />
        <v-divider class="my-5"></v-divider>
        <ListsMenu
          v-if="!$vuetify.breakpoint.sm"
          :listsData="lists"
          @setdefault="setDefaultList"
          @remove="removeList"
          @rename="renameList"
          @created="onListCreated"
        />

        <v-card
          light
          outlined
          v-if="lists && lists.length == 0"
          class="pa-2 text-center"
          style="width:100%;"
        >
          <v-card-title class="headline">Nessuna lista trovata</v-card-title>
        </v-card>
      </v-col>
      <v-col class="relative" cols="12" md="9">
        <!-- pagina principale: dettaglio lista -->
        <!-- <ProfileTitle :category="category" /> -->
        <ListPreferred v-if="listId == 'preferred'" />
        <ListDetail v-else-if="listId" :listId="listId" />
        <div v-else>Selezionare una lista</div>
        <v-speed-dial
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="fab"
          :right="true"
          fixed
          direction="top"
          transition="slide-y-reverse-transition"
          class="lists-filter"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="secondary" fab>
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-format-list-bulleted-square
              </v-icon>
            </v-btn>
          </template>
          <v-card @click.stop.prevent="" v-ripple="false">
            <ListsMenu
              :listsData="lists"
              @setdefault="setDefaultList"
              @remove="removeList"
              @rename="renameList"
              @created="onListCreated"
            />
          </v-card>
        </v-speed-dial>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// @ is an alias to /src
// import ProfileTitle from "@/components/profile/ProfileTitle";
import MenuNavigator from "@/components/profile/MenuNavigator.vue";

import ListsMenu from "@/components/lists/ListsMenu.vue";
import ListDetail from "@/components/lists/ListDetail.vue";
import ListPreferred from "@/components/lists/ListPreferred.vue";

import list from "~/mixins/list";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "Lists",
  mixins: [list, categoryMixins, deliveryReactive],
  data() {
    return {
      page: {},
      listId: null,
      fab: false,
      prof: false
    };
  },
  components: {
    MenuNavigator,
    ListsMenu,
    ListDetail,
    ListPreferred
  },
  methods: {
    checkListId() {},
    onListCreated() {
      this.reload();
    }
  },
  created() {
    this.reload();
  },
  watch: {
    lists() {
      if (this.$route && this.$route.params && !this.$route.params.listId) {
        for (var i = 0; i < this.lists.length; i++) {
          if (this.lists[i].isDefault) {
            this.listId = this.lists[i].id;
            this.$router.push({
              name: "List",
              params: { listId: this.listId }
            });
          }
        }
      } else {
        this.listId = this.$route.params.listId;
      }
    },
    $route(to) {
      this.listId = to.params.listId;
    }
  }
};
</script>
